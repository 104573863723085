import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StructuredText, renderRule } from 'react-datocms';
import { isHeading, isBlockquote } from 'datocms-structured-text-utils';

const HeroBanner = ({ block, pageClass }) => {

    if (!block || !block.bannerStructure || block.bannerStructure.blocks.length === 0) {
        return null; 
    }

    const getCardClass = (cardSide) => {
        switch (cardSide) {
            case 'direita':
                return `page-right-${pageClass} flex flex-col lg:flex-row items-center min-h-[445px] sm:mx-[30px] md:mx-[100px]`;
            case 'esquerda':
                return `page-left-${pageClass} flex flex-col-reverse lg:flex-row-reverse items-center min-h-[445px] sm:mx-[30px] md:mx-[100px]`;
            case 'acima':
                return `page-up-${pageClass} flex flex-wrap`;
            case 'abaixo':
                return `page-down-${pageClass} flex flex-wrap`;
            default:
                return '';
        }
    };

    const getImageClassChild = (cardSide) => {
        switch (cardSide) {
            case 'direita':
                return 'lg:flex-[0_0_50%] lg:p-[57px] px-[35px]';
            case 'esquerda':
                return 'lg:flex-[0_0_50%] lg:p-[57px] px-[35px]';
            case 'acima':
                return 'flex-wrap flex-[0_0_100%] order-none';
            case 'abaixo':
                return 'flex-wrap flex-[0_0_100%] order-1';
            default:
                return '';
        }
    };

    const getTextClassChild = (cardSide) => {
        switch (cardSide) {
            case 'direita':
                return 'lg:flex-[0_0_50%] lg:p-[57px] px-[35px]';
            case 'esquerda':
                return 'lg:flex-[0_0_50%] lg:p-[57px] px-[35px]';
            case 'acima':
                return 'flex-[0_0_100%] order-1';
            case 'abaixo':
                return 'flex-[0_0_100%] order-none';
            default:
                return '';
        }
    };

    const customRenderRules = [
        renderRule(isHeading, ({ node, children, key }) => {
            return React.createElement(
                `h${node.level}`,
                { key, className: `font-bold font-serif ${pageClass}-heading` },
                children
            );
        }),
        renderRule(isBlockquote, ({ node, children, key }) => (
            <blockquote key={key} className={`font-bold mb-[25px] text-2xl text-left ${pageClass}-blockquote`}>
                {children}
            </blockquote>
        )),

    ];

    return (
        <div className="flex flex-[0_0_100%]">
            { block.bannerStructure.blocks.map((coluna) => (
                <div
                    className={`${getCardClass(coluna.cardSide)} flex-[0_0_100%] mb-[35px] md:mb-[108px] justify-center`}
                    key={coluna.id}
                >
                    <div
                        className={`${getTextClassChild(coluna.cardSide)} banner-history ${!coluna.boxShadow ? 'gradient-main-background' : ''}`}
                    >
                        <div
                            className="p-[22px]
                                       text-left
                                       flex flex-col
                                       justify-center items-center
                                       rounded-s-3xl
                                       border-styles
                                       "
                        >
                            <StructuredText
                                data={coluna.paragraphs}
                                customNodeRules={customRenderRules}
                            />
                        </div>
                    </div>
                    {coluna.image && (
                        <GatsbyImage
                            className={getImageClassChild(coluna.cardSide)}
                            image={getImage(coluna.image.gatsbyImageData)}
                            alt={coluna.image.alt}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default HeroBanner;
