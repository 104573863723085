import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

const SubcatLink = ({ mainCategoryId, mainCategorySlug }) => {
    const { allCategories } = useStaticQuery(graphql`
    query {
      allCategories: allDatoCmsCategory {
        nodes {
          id
          name
          slug
          isMainCategory
          categoryMain {
            id
            slug 
          }
        }
      }
    }
  `);

    const subcategories = allCategories.nodes.filter(subCat =>
        subCat.categoryMain && subCat.categoryMain.id === mainCategoryId
    );

    if (subcategories.length === 0) {
        return null;
    }

    return (
        <ul className="flex flex-wrap text-[#e5d491] justify-center p-[20px] md:p-[unset] md:mt-20 font-bold w-full">
            {subcategories.map(sub => (
                <li
                    key={sub.id}
                    className="
                               text-center
                               flex-[0_0_100%]
                               md:flex-[0_0_30%]
                               m-6
                               gradient-main-background
                               rounded-lg
                               border
                               border-4
                               border-[#e5d491]
                               keep
                               "
                >
                    <Link
                        to={`/${sub.slug}`}
                        className="block w-full h-full text-[26px] leading-[72px]"
                    >
                        {sub.name}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default SubcatLink;
