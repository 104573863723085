import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StructuredText, renderRule } from 'react-datocms';
import { isHeading, isParagraph, isBlockquote } from 'datocms-structured-text-utils';

const ContactUs = ({ id, dogCategoryContactUs }) => {
    const customRenderRules = [
        renderRule(
            isHeading, (
                { node, children, key }
            ) => {
            return React.createElement(
                `h${node.level}`,
                { key, className: 'text-xl font-bold' },
                children
            );
        }),
        renderRule(isParagraph, (
            { children, key }
        ) => {
            return(
                <p
                    key={key}
                    className="text-base mb-4">
                    {children}
                </p>
            )
        }),
        renderRule(isBlockquote, (
            { children, key }
        ) => {
            return(
                <blockquote
                    key={key}
                    className="italic border-l-4 pl-4"
                >
                    {children}
                </blockquote>
            )
        }),
    ];

    let imageData = null;
    let structuredTextData = null;

    if (dogCategoryContactUs && dogCategoryContactUs.blocks && dogCategoryContactUs.blocks.length > 0) {
        const nestedBlocks = dogCategoryContactUs.blocks[0].cardStructure.blocks;

        if (nestedBlocks && nestedBlocks.length > 0) {
            const imageBlock = nestedBlocks.find(block => block.image);
            const textBlock = nestedBlocks.find(block => block.paragraphs);

            if (imageBlock && imageBlock.image) {
                imageData = getImage(imageBlock.image.gatsbyImageData);
            }
            if (textBlock && textBlock.paragraphs) {
                structuredTextData = textBlock.paragraphs.value;
            }
        }
    }

    return (
        <a href="https://api.whatsapp.com/send?phone=5511984164914" target="_blank" rel="noopener noreferrer" className="block">
        <div className="contact-background p-10 w-full" id={id}>
            <div className="flex flex-col md:flex-row justify-center items-center">
                {imageData && (
                    <div className="flex-[0_0_20%] mb-6 lg:mb-0">
                        <GatsbyImage
                            image={imageData}
                            alt="Contact Us"
                        />
                    </div>
                )}
                {structuredTextData && (
                    <div className="flex-[0_0_auto] text-center lg:text-left text-white text-2xl">
                        <StructuredText
                            data={structuredTextData}
                            customNodeRules={customRenderRules}
                        />
                    </div>
                )}
            </div>
        </div>
        </a>
    );
};

export default ContactUs;