import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {StructuredText, renderNodeRule, renderRule} from 'react-datocms';
import {isBlockquote, isHeading, isParagraph} from 'datocms-structured-text-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MiddleBanner = ({ block }) => {

    const customRenderRules = [
        renderRule(isHeading, ({ node, children, key }) => {
            return React.createElement(
                `h${node.level}`,
                { key, className: 'font-bold font-serif' },
                children
            );
        }),
        renderRule(isBlockquote, ({ children, key }) => {
            return (
                <blockquote
                    className="font-bold mb-[25px] text-2xl text-left"
                    key={key}
                >
                    {children}
                </blockquote>
            );
        }),
    ];

    return (
        <>
            {block.middleBannerStructure.blocks.map((coluna) => (
                <div className="flex" key={coluna.id}>
                    <div className="middle-banner-canil border-styles">
                        <div className="flex  p-[57px] items-center flex-wrap justify-center">
                            <div className="p-[22px] flex-[0_0_100%] md:flex-[0_0_60%]">
                                {coluna.iconFont && (
                                    <FontAwesomeIcon
                                        icon={['fas', coluna.iconFont]} // Ensure this is correctly referencing your icon
                                        className="mr-2 text-3xl relative"
                                    />
                                )}
                                <StructuredText
                                    data={coluna.paragraphs}
                                    customNodeRules={customRenderRules}
                                />
                            </div>
                            {coluna.image && (
                                <GatsbyImage
                                    className="flex-[0_0_100%] md:flex-[0_0_40%] p-[57px]"
                                    image={getImage(coluna.image.gatsbyImageData)}
                                    alt={coluna.image.alt || 'Middle Banner Image'}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default MiddleBanner;
